<template>
    <section id="contact" style="background-color: #3c3c3c !important;">
      <section class="pa-5">
        <div class="container mx-auto">
          <div class="d-flex footer justify-space-between">
            <div class="d-flex-col desc pt-2">
              <div>
                <span class="fs-24 fw-600 text--white">Travel with Amarta</span>
              </div>
              <div class="d-flex ga-2 pt-2">
                <span class="text--white text-align-justify ">
                  Gedung Jaya, Jl. M.H. Thamrin No.12 Lantai 5 Unit A.6, RT.2/RW.1, Kb. Sirih, Kec. Menteng, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10340
                </span>
              </div>
            </div>
            <div class="hide-on-mobile pt-2">
              <ul class="d-flex ga-1">
                <li><router-link to="/tentang-kami" class="fs-16 text--white cursor-pointer">Tentang Kami</router-link></li>
                <li><router-link to="/layanan" class="fs-16 text--white cursor-pointer">Layanan</router-link></li>
                <li><router-link to="/produk" class="fs-16 text--white cursor-pointer">Produk</router-link></li>
                <li><a href="#gallery" class="fs-16 text--white cursor-pointer">Galeri</a></li>
                <li><a href="#form" class="fs-16 text--white cursor-pointer">Formulir</a></li>
                <!-- <li><a href="#contact" class="fs-16 text--white cursor-pointer">Kontak</a></li> -->
              </ul>
            </div>
            <div class="d-flex-col pt-2">
              <div>
                <span class="fs-24 text--white ">Hubungi Kami</span>
              </div>
              <div class="pt-2">
                <a href="https://api.whatsapp.com/send/?phone=628818200436&text&type=phone_number&app_absent=0" target="_blank">
                  <div class="d-flex ga-2">
                    <i class="fa-brands fa-whatsapp text--white align-self-center"></i>
                    <span class="fs-16 text--white fw-400">08818200436</span>
                  </div>
                </a>
                <div class="d-flex ga-2 pt-2">
                  <i class="fa-regular fa-envelope text--white align-self-center"></i>
                  <span class="fs-16 text--white fw-400">twatravelwithamarta@gmail.com</span>
                </div>
                <a href="https://www.tiktok.com/@travelwithamarta" target="_blank">
                  <div class="d-flex ga-2 pt-2">
                    <i class="fa-brands fa-tiktok text--white align-self-center"></i>
                    <span class="fs-16 text--white fw-400">@travelwithamarta</span>
                  </div>
                </a>
                <a href="https://www.instagram.com/travelwithamarta/" target="_blank">
                  <div class="d-flex ga-2 pt-2">
                    <i class="fa-brands fa-instagram text--white align-self-center"></i>
                    <span class="fs-16 text--white fw-400">@travelwithamarta</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- <div class="bg-white pa-1"></div> -->
    </section>
</template>
<script>
export default {
  name: 'FooterComponent',
}
</script>