<template>
  <div>
    <div class="bg-main-image">
      <!-- Navbar -->
      <NavbarComponent/>
      <!-- Home -->
      <section id="home" class="pt-16 pl-2 pr-2" style="padding-bottom: 69px;">
        <div class="container mx-auto d-flex justify-center" style="height: 80vh;">
          <div class="align-content-center">
            <div>
              <span class="text--maincolor ff-Custom-1 fs-80">Japan Edutravel</span>
            </div>
            <div class="d-flex justify-center">
              <span class="bg-maincolor pa-1 pl-16 pr-16 fs-20 br-20px fw-600">by Travel with Amarta</span>
            </div>
          </div>
        </div>
        <!-- <div class="d-flex justify-center">
          <span class="border-2px-white pa-3 br-20px bg-main-color cursor-pointer selengkapnya">LIHAT SELENGKAPNYA</span>
        </div> -->
      </section>
    </div>

    <!-- Testimoni -->
     <section id="testimoni"  class="pt-16 pl-2 pr-2">
       <div class="text-center mb-5">
         <h2 class="fs-48 ff-Custom-1">Explore Japan’s Best Highlight</h2>
       </div>
      <div class="container mx-auto flex flex-col j md:flex-row justify-center gap-10">
        <div class="items-center">
          <iframe class="w-full md:w-[303px] h-[500px]" src="https://www.youtube.com/embed/ZykJQAq6aqg" title="Testimoni edutrip dari SMP Alam Cikeas 😊 #studentexchange #studytour #edutripjepang #sisterschool" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
        <div>
          <iframe class="w-full  md:w-[303px] h-[500px]" src="https://www.youtube.com/embed/6url3_8pvLM" title="Edutrip ke Jepang belajar apa aja? #studytour #studentexchange #studytourjapan #edutripjepang" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
      </div>
     </section>
    <!-- About -->
    <section id="about" class="pt-16 pl-2 pr-2">
      <div class="container mx-auto d-flex mt-16 pb-16">
        <div class="grid-box-2 ">
          <v-col class="d-flex-col justify-center">
            <div class="pb-2">
              <span class="fs-48 ff-Custom-1">Tentang Kami</span>
            </div>
            <div class="text-align-justify">
              <p class="fs-16">CV Travel With Amarta (TWA) merupakan perusahaan konsultan yang menyediakan jasa perjalanan ke Jepang. 
                Kami telah beroperasi sejak 2016 (melalui CV Prima Interindo). Dengan pembaruan, kami hadir untuk memberikan pengalaman dan pelayanan 
                terbaik melalui berbagai pilihan program yang dapat disesuaikan dengan kebutuhan klien. Kami juga dapat menyediakan halal edutravel bagi instansi yang membutuhkan.  <a href="https://drive.google.com/drive/folders/1jyQ9uqq6zfB5Xb4ZTqHEqQisVM3eXIHs" class="text-red-darken-1 fw-600" target="_blank">Cek legalitas kami disini.</a></p>
               
              <p class="fs-16 pt-5">Kami adalah travel konsultan berbasis edukasi tur, yang menggabungkan konsep belajar formal dan non formal. Program kami menyediakan sarana eksplorasi aktif untuk peserta agar mereka dapat mengaplikasikan ilmu secara langsung di dunia sekitar. Jepang menjadi fokus utama destinasi kami karena menawarkan banyak destinasi wisata edukasi yang inteaktif dan inovatof untuk dipelajari. Selain itu Jepang diakui secara internasional sebagai negara yang memiliki sistem pendidikan percontohan di Asia. Kami hadir untuk memberikan pengalaman dan pelayanan terbaik melalui berbagai pilihan program yang dapat disesuaikan dengan kebutuhan klien. Kami juga dapat menyediakan halal edutravel bagi instansi yang membutuhkan.</p>
            </div>
          </v-col>
          <v-col class="text-align-center">
           <div class="grid-box-2 about-img">
            <div class="image-container">
              <img class="img-fluid" src="../assets/images/performancekebudayaan.png" alt="Performance Kebudayaan">
              <span class="bg-red-darken-4 br-20px pa-1">Performance Kebudayaan</span>
            </div>
            <div class="image-container">
              <img class="img-fluid" src="../assets/images/museumvisit.png" alt="Museum Visit">
              <span class="bg-red-darken-4 br-20px pa-1">Museum Visit</span>
            </div>
            <div class="image-container">
              <img class="img-fluid" src="../assets/images/companyvisit.png" alt="Company Visit">
              <span class="bg-red-darken-4 br-20px pa-1">Company Visit</span>
            </div>
            <div class="image-container">
              <img class="img-fluid" src="../assets/images/kunjunganpersahabatan.png" alt="Kunjungan Persahabatan">
              <span class="bg-red-darken-4 br-20px pa-1">Kunjungan Persahabatan</span>
            </div>
           </div>
          </v-col>
        </div>
      </div>
      <!-- <div class="container-dqa d-flex mt-16 pb-16">
        <div class="grid-box-2 ">
          <v-col class="text-align-center">
            <img class="img-fluid image-shadow" src="../assets/images/edutravel.png" height="800" width="500">
          </v-col>
          <v-col class="d-flex-col justify-center">
            <div class="pb-2">
              <span class="fs-48 ff-Custom-1">Tentang Edutravel</span>
            </div>
            <div class="text-align-justify">
              <span class="fs-16">Kami adalah travel konsultan berbasis edukasi tur, yang menggabungkan konsep belajar formal dan non formal. Program kami menyediakan sarana eksplorasi aktif untuk peserta agar mereka dapat mengaplikasikan ilmu secara langsung di dunia sekitar. Jepang menjadi fokus utama destinasi kami karena menawarkan banyak destinasi wisata edukasi yang inteaktif dan inovatof untuk dipelajari. Selain itu Jepang diakui secara internasional sebagai negara yang memiliki sistem pendidikan percontohan di Asia. Kami hadir untuk memberikan pengalaman dan pelayanan terbaik melalui berbagai pilihan program yang dapat disesuaikan dengan kebutuhan klien. Kami juga dapat menyediakan halal edutravel bagi instansi yang membutuhkan.</span>
            </div>
          </v-col>
        </div>
      </div> -->
    </section>

    <!--Service  -->
    <section id="service" class="pt-16 " >
      <div class="container mx-auto d-flex-col">
        <div class="align-self-center">
          <span class="fs-48 ff-Custom-1">Layanan</span>
        </div>

        <div class="grid-box-service ga-16 pt-5 pl-3 pr-3" >
          <div class="d-flex-col">
              <div class="d-flex justify-center mb-2">
                <div class="border-point-red"></div>
                <h2 class="fs-30 ff-Custom-1">Perencanaan Program</h2>
              </div>
              <span class="fs-16 text-align-justify">Kami membantu klien untuk menentukan tujuan serta fokus pembelajaran sesuai kurikulum dan program sekolah. Dilanjutkan dengan merancang program wisata edukasi yang bermanfaat untuk peserta didik, yang disesuaikan dengan kebutuhan program pembelajaran serta anggaran sekolah.</span>
          </div>
          <div class="d-flex-col">
            <div class="d-flex justify-center mb-2">
              <div class="border-point-red"></div>
              <h2 class="fs-30 ff-Custom-1">Pemandu Perjalanan</h2>
            </div>
            <div class="d-flex">
              <span class="text-align-justify fs-16">Kami membantu klien selama perjalanan dengan memberikan panduan dan pendampingan perjalanan pada aspek transportasi, akomodasi serta konsumsi, berikut dengan fasilitas penerjemah selama proses pembelajaran.</span>
            </div>
          </div>
          <div>
          <div class="d-flex justify-center">
            <div class="border-point-red"></div>
            <h2 class="fs-30 ff-Custom-1">Tujuan Perjalanan :</h2>
          </div>
          <div class="d-flex">
            <div class="grid-box-2 mt-1">
              <div class="d-flex-col">
                <span class="fs-16">•	Perusahaan & industri</span>
                <span class="fs-16">•	Sekolah & universitas</span>
                <span class="fs-16">•	Instansi pemerintah</span>
                <span class="fs-16">•	Yayasan sosial</span>
                <span class="fs-16">•	Museum</span>
              </div>
              <div class="d-flex-col">
                <span class="fs-16">•	Cagar alam & hutan kota</span>
                <span class="fs-16">•	Desa lokal</span>
                <span class="fs-16">•	Objek wisata</span>
                <span class="fs-16">•	Pusat perbelanjaan</span>
                <span class="fs-16">•	Tempat ibadah</span>
              </div>
            </div>
          </div>
        </div>
        </div>

        

        <div class="py-1 px-3">
            <div class="container-dqa mt-16 pb-16">
                <div class="flex justify-center">
                    <h2 class="ff-Custom-1 text-[40px]">Mengapa Memilih Kami</h2>
                </div>
                <div class="grid grid-cols-4 md:grid-cols-12 gap-5 py-5">
                    <div class="col-span-4 border shadow rounded-lg pa-5 bg-white">
                        <div class="flex justify-center pb-2 ">
                            <i class="fa-solid fa-route text-[50px] border rounded-full w-[100px] h-[100px] content-center text-center bg-white"></i>
                        </div>
                        <p class="text-[#2c3e50]">Membuat dan mengakomodasi program unik yang disesuaikan dengan kebutuhan grup</p>
                    </div>
                    <div class="col-span-4 border shadow rounded-lg pa-5 bg-white">
                        <div class="flex justify-center pb-2">
                            <i class="fa-solid fa-chalkboard text-[50px] border rounded-full w-[100px] h-[100px] content-center text-center bg-white"></i>
                        </div>
                        <p class="text-[#2c3e50]">Menghubungkan kemitraan Indonesia-Jepang melalui program kurikulum seperti Sister School, Homestay, dan Student Exchange.</p>
                    </div>
                    <div class="col-span-4 border shadow rounded-lg pa-5 bg-white">
                        <div class="flex justify-center pb-2">
                            <i class="fa-solid fa-book text-[50px] border rounded-full w-[100px] h-[100px] content-center text-center bg-white"></i>
                        </div>
                        <p class="text-[#2c3e50]">Mengakomodasi untuk belajar dari tenaga ahli dari setiap bidangnya</p>
                    </div>
                    <div class="col-span-4 col-start-1 md:col-start-3 shadow border rounded-lg pa-5 bg-white">
                        <div class="flex justify-center pb-2">
                            <i class="fa-solid fa-money-bill-wave text-[50px] border rounded-full w-[100px] h-[100px] content-center text-center bg-white"></i>
                        </div>
                        <p class="text-[#2c3e50]">Fleksibilatas harga mengikuti permintaan disesuaikan dengan fasilitas akomodasi yang beragam</p>
                    </div>
                    <div class="col-span-4  border rounded-lg pa-5 bg-white">
                        <div class="flex justify-center pb-2">
                            <i class="fa-solid fa-bowl-food text-[50px] border rounded-full w-[100px] h-[100px] content-center text-center bg-white"></i>
                        </div>
                        <p class="text-[#2c3e50]">Menyediakan halal tur dengan mengkurasi pilihan bento, snack, serta mengingatkan jam dan tempat sholat (jika tersedia)</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </section>

    <!-- Product -->
    <section class="container-dqa mx-auto pt-16 pb-16">
        <div class="mt-16 text-center">
            <h1 class="fs-48 ff-Custom-1">Eksplor Keindahan Jepang: Liburan Impian Menanti! 🌸✈️</h1>
        </div>
          <div class="grid grid-cols-1 md:grid-cols-3 gap-5 mt-10 px-4">
              <div v-for="(produk, index) in produkList" :key="index" class="border shadow rounded-lg">
                  <router-link :to="{ name: 'ProdukDetail', params: { id: index + 1 } }">
                      <img :src="produk.image" :alt="produk.name" class="img-fluid rounded-lg">
                  </router-link>
                  <div class="px-3 flex flex-col gap-3 py-3">
                      <h2 class="font-bold">{{ produk.name }}</h2>
                  </div>
              </div>
          </div>
    </section>
    
    <!-- Gallery -->
    <section id="gallery" class="pt-16">
      <div class="container mx-auto">
        <div class="d-flex-col pl-3 pr-3">
          <div class="align-self-center">
            <span class="fs-48 ff-Custom-1">Galeri</span>
          </div>
          <swiper
            :slidesPerView="3"
            :grid="{
              rows: 2,
            }"
            :spaceBetween="30"
            :pagination="{
              clickable: true,
            }"
            :modules="modules"
            :navigation="true"
            class="mySwiper hide-on-mobile"
          >
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_0019.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_0104.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_0311.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_0475.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_3671.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_0774.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_0934.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_0968.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_1246.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_1407.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_3560.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_3868.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_3880.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_4003.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_4013.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_4105.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_4204.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_9952.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_9988.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_20230514_205108.jpg" />
            </swiper-slide>
    
          </swiper>

          <!-- Mobile -->
          <swiper
            :slidesPerView="2"
            :spaceBetween="30"
            :pagination="{
              clickable: true,
            }"
            :modules="modules"
            :navigation="true"
            class="mySwiper show-on-mobile"
          >
          <swiper-slide>
              <img src="../assets/images/galeri/IMG_0019.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_0104.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_3671.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_0311.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_0475.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_0774.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_0934.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_0968.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_1246.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_1407.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_3560.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_3868.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_3880.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_4003.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_4013.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_4105.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_4204.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_9952.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_9988.jpg" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/images/galeri/IMG_20230514_205108.jpg" />
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </section>

    <!-- Form -->
    <section id="form" class="pt-16 pb-11">
      <div class="container mx-auto">
          <div class="text-align-center mb-2">
            <span class="fs-48 ff-Custom-1">Rencanakan Perjalanan</span>
          </div>
          <div class="d-flex justify-center pl-3 pr-3">
            <div class="form-container image-shadow">
              <form @submit.prevent="handleSubmit">
                <div class="form-row">
                  <div class="form-group">
                    <label for="namaInstansi">Nama Instansi: <span style="color: red">*</span></label>
                    <input type="text" v-model="form.namaInstansi" id="namaInstansi" required />
                  </div>
                  <div class="form-group">
                    <label for="emailInstansi">Email Instansi: <span style="color: red">*</span></label>
                    <input type="email" v-model="form.emailInstansi" id="emailInstansi" required />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group">
                    <label for="phone">Nomor Telepon: <span style="color: red">*</span></label>
                    <input type="tel" v-model="form.phone" id="phone" required />
                  </div>
                  <div class="form-group">
                    <label for="jumlahPeserta">Perkiraan Jumlah Peserta: <span style="color: red">*</span></label>
                    <input type="number" v-model="form.jumlahPeserta" id="jumlahPeserta" required />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group">
                    <label for="tanggalKeberangkatan">Perkiraan Tanggal Keberangkatan: <span style="color: red">*</span></label>
                    <input type="date" v-model="form.tanggalKeberangkatan" :min="today" id="tanggalKeberangkatan" required />
                  </div>
                  <div class="form-group">
                    <label for="tanggalKepulangan">Perkiraan Tanggal Kepulangan: <span style="color: red">*</span></label>
                    <input type="date" v-model="form.tanggalKepulangan"  :min="today" id="tanggalKepulangan" required />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group">
                    <label for="kotaTujuan">Kota/Tempat yang Ingin Dikunjungi: <span style="color: red">*</span></label>
                    <input type="text" v-model="form.kotaTujuan" id="kotaTujuan" required />
                  </div>
                  <div class="form-group">
                    <label for="akomodasi">Akomodasi yang Diinginkan: <span style="color: red">*</span></label>
                    <select v-model="form.akomodasi" id="akomodasi" required>
                      <option disabled value="">Pilih akomodasi</option>
                      <option>Hotel</option>
                      <option>Dorm</option>
                      <option>Apartment</option>
                      <option>Homestay</option>
                    </select>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group">
                    <label for="service">Service: <span style="color: red">*</span></label>
                    <select v-model="form.service" id="service" required>
                      <option disabled value="">Pilih service</option>
                      <option>Pesawat + Visa + Program</option>
                      <option>Pesawat + Program</option>
                      <option>Program Saja</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="temaWisata">Tema / Fokus Wisata:</label>
                    <input type="text" v-model="form.temaWisata" id="temaWisata" />
                  </div>
                </div>
                <div class="form-group">
                  <label for="budget">Perkiraan Budget per Pax yang Dikehendaki:</label>
                  <div class="input-wrapper">
                    <span class="mr-2">Rp</span>
                    <input type="number" v-model="form.budget" id="budget" />
                  </div>
                </div>
                <div class="form-group">
                  <span class="fw-600 font-italic">Keterangan : Proses penghitungan biaya berlangsung selama 3-7 hari kerja.</span>
                </div>
                <div class="d-flex justify-end">
                  <button class="border pa-1 pl-3 pr-3 br-5px bg-success text--white" type="submit">Kirim</button>
                </div>
              </form>
            </div>
          </div>
      </div>
    </section>

    <!-- Footer -->
    <FooterComponent/>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/grid';
import {  Pagination,Navigation , Grid} from 'swiper/modules';
import NavbarComponent from '../components/navbar.vue'
import FooterComponent from '../components/footer.vue'

export default {
  name: 'HomePage',
  components: {
    Swiper,
    SwiperSlide,
    NavbarComponent,
    FooterComponent
  },
  data(){
    return{
      initialSlide: 4,
      form: {
          namaInstansi: '',
          emailInstansi: '',
          phone: '',
          jumlahPeserta: '',
          tanggalKeberangkatan: '',
          tanggalKepulangan: '',
          kotaTujuan: '',
          akomodasi: '',
          temaWisata: '',
          budget: '',
          service: ''
        },
      today: '',
      produkList: [
          { id: 1, name: "Japan Edutrip Custom Group", image: require('../assets/images/produk-3.png') },
          { id: 2, name: "PRIVATE GROUP EDUTRIP - KANSAI JAPAN", image: require('../assets/images/produk-2.png') },
          { id: 3, name: "PRIVATE GROUP EDUTRIP - KANTO", image: require('../assets/images/produk-1.png') }
      ]
    }
  },
  setup() {
    return {
      modules: [ Pagination, Navigation, Grid],
    };
  },
  methods:{
    handleSubmit() {
      const formData = new FormData();
      Object.keys(this.form).forEach(key => {
        formData.append(key, this.form[key]);
      });

      fetch('http://travelwithamarta.com/sendmail.php', {
        method: 'POST',
        body: formData,
      })
      .then(response => response.text())
      .then(data => {
        console.log(data);
        alert('Email sent successfully!');
        location.reload();
      })
      .catch(error => {
        console.error('Error:', error);
        alert('Failed to send email.');
      });
    },
    setTodayDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      this.today = `${year}-${month}-${day}`;
    },
    // showSection(sectionId) {
    //   const sectionOrder = {
    //     'form': ['about', 'service', 'gallery', 'form'],
    //     'contact': ['about', 'service', 'gallery', 'form', 'contact'],
    //     'gallery': ['about', 'service', 'gallery'],
    //     'service': ['about', 'service'],
    //     'about': ['about']
    //   };

    //   const newSections = sectionOrder[sectionId] || [];
      
    //   this.visibleSections = [...new Set([...this.visibleSections, ...newSections])];

    //   this.$nextTick(() => {
    //     const section = document.getElementById(sectionId);
    //     if (section) {
    //       section.scrollIntoView({ behavior: 'smooth' });
    //     }
    //   });
    // }
  },
  // mounted(){
  //   this.setTodayDate();

  //   var navbar = this.$el.querySelector("#navbar");
  //   var navbarMobile = this.$el.querySelector("#navbar-mobile");
  //   var toggleBars = this.$el.querySelector("#toggleBar")

  //   window.addEventListener("scroll", function(){
  //     navbar.classList.toggle("sticky-navbar",window.scrollY > 0)
  //     navbarMobile.classList.toggle("sticky-navbar",window.scrollY > 0)
      
  //   })
   
  //   toggleBars.addEventListener("click", function(){
  //     navbarMobile.style.display =(navbarMobile.style.display === 'none' || navbarMobile.style.display === '') ? 'block' : 'none';
  //   })

  //   var navbarMobileLinks = navbarMobile.querySelectorAll('li');
  //   navbarMobileLinks.forEach(function (link) {
  //     link.addEventListener('click', function () {
    
  //       navbarMobile.style.display = 'none';
  //     });
  //   });

  //   // const homeSection = document.getElementById('home');
  //   // if (homeSection) {
  //   //   homeSection.scrollIntoView();
  //   // }
  // }
}

  

</script>