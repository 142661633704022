<template>
<div >
    <NavbarComponent/>
    <section class="md:text-center px-6 md:content-center pt-16 pb-10 mt-16">
        <h2 class="text-3xl md:fs-48 font-bold text-gray-800 ff-Custom-1">Travel Impian, Harga Fleksibel, Pengalaman Bermakna! ✨🌍</h2>
        <p class="mt-3 text-lg text-gray-700">
            🚀 Mau Liburan Tanpa Ribet & Sesuai Budget?<br />
            Bersama kami, perjalanan bukan hanya sekadar wisata, tapi juga pengalaman penuh makna!
        </p>
        
        <div class="mt-5 space-y-2 text-gray-800">
            <p>✅ <strong>Harga Fleksibel</strong> – Sesuaikan dengan kebutuhan & budget Anda</p>
            <p>✅ <strong>Kemitraan Luas</strong> – Jaringan terpercaya untuk layanan terbaik</p>
            <p>✅ <strong>Halal Friendly</strong> – Makanan, hotel, & destinasi yang nyaman bagi Muslim</p>
            <p>✅ <strong>Rute & Program Khusus</strong> – Disesuaikan dengan keinginan Anda</p>
        </div>

        <p class="mt-4 font-semibold text-gray-700">
            💡 Yuk, Wujudkan Perjalanan Bermakna Sekarang! Isi formulir di bawah & tim kami siap membantu.
        </p>

        <button @click="isModalOpen = true" class="mt-6 px-6 py-3 bg-green font-bold rounded-lg shadow-md" >
            📩 Daftar Sekarang & Konsultasi Gratis! 👇
        </button>
    </section>
    <section id="form" class="my-16" >
        <div class="container-dqa ">
            <!-- <div class="text-align-center mb-2">
                <span class="fs-48 ff-Custom-1">Rencanakan Perjalanan</span>
            </div> -->
            <div class="d-flex justify-center pl-3 pr-3 max-h-[500px] md:max-h-[700px] overflow-y-auto rounded-lg" >
                <div class="form-container image-shadow">
                <form @submit.prevent="handleSubmit">
                    <div class="form-row">
                    <div class="form-group">
                        <label for="namaInstansi">Nama Instansi: <span style="color: red">*</span></label>
                        <input type="text" v-model="form.namaInstansi" id="namaInstansi" required />
                    </div>
                    <div class="form-group">
                        <label for="emailInstansi">Email Instansi: <span style="color: red">*</span></label>
                        <input type="email" v-model="form.emailInstansi" id="emailInstansi" required />
                    </div>
                    </div>
                    <div class="form-row">
                    <div class="form-group">
                        <label for="phone">Nomor Telepon: <span style="color: red">*</span></label>
                        <input type="tel" v-model="form.phone" id="phone" required />
                    </div>
                    <div class="form-group">
                        <label for="jumlahPeserta">Perkiraan Jumlah Peserta: <span style="color: red">*</span></label>
                        <input type="number" v-model="form.jumlahPeserta" id="jumlahPeserta" required />
                    </div>
                    </div>
                    <div class="form-row">
                    <div class="form-group">
                        <label for="tanggalKeberangkatan">Perkiraan Tanggal Keberangkatan: <span style="color: red">*</span></label>
                        <input type="date" v-model="form.tanggalKeberangkatan" :min="today" id="tanggalKeberangkatan" required />
                    </div>
                    <div class="form-group">
                        <label for="tanggalKepulangan">Perkiraan Tanggal Kepulangan: <span style="color: red">*</span></label>
                        <input type="date" v-model="form.tanggalKepulangan"  :min="today" id="tanggalKepulangan" required />
                    </div>
                    </div>
                    <div class="form-row">
                    <div class="form-group">
                        <label for="kotaTujuan">Kota/Tempat yang Ingin Dikunjungi: <span style="color: red">*</span></label>
                        <input type="text" v-model="form.kotaTujuan" id="kotaTujuan" required />
                    </div>
                    <div class="form-group">
                        <label for="akomodasi">Akomodasi yang Diinginkan: <span style="color: red">*</span></label>
                        <select v-model="form.akomodasi" id="akomodasi" required>
                        <option disabled value="">Pilih akomodasi</option>
                        <option>Hotel</option>
                        <option>Dorm</option>
                        <option>Apartment</option>
                        <option>Homestay</option>
                        </select>
                    </div>
                    </div>
                    <div class="form-row">
                    <div class="form-group">
                        <label for="service">Service: <span style="color: red">*</span></label>
                        <select v-model="form.service" id="service" required>
                        <option disabled value="">Pilih service</option>
                        <option>Pesawat + Visa + Program</option>
                        <option>Pesawat + Program</option>
                        <option>Program Saja</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="temaWisata">Tema / Fokus Wisata:</label>
                        <input type="text" v-model="form.temaWisata" id="temaWisata" />
                    </div>
                    </div>
                    <div class="form-group">
                    <label for="budget">Perkiraan Budget per Pax yang Dikehendaki:</label>
                    <div class="input-wrapper">
                        <span class="mr-2">Rp</span>
                        <input type="number" v-model="form.budget" id="budget" />
                    </div>
                    </div>
                    <div class="form-group">
                    <span class="fw-600 font-italic">Keterangan : Proses penghitungan biaya berlangsung selama 3-7 hari kerja.</span>
                    </div>
                    <div class="d-flex justify-end">
                    <button class="border pa-1 pl-3 pr-3 br-5px bg-success text--white" type="submit">Kirim</button>
                    </div>
                </form>
                </div>
            </div>
        </div>
    </section>

    <!-- <FooterComponent/> -->
</div>
</template>
<script>
import NavbarComponent from '../components/navbar.vue'
// import FooterComponent from '../components/footer.vue'

export default {
    name: 'FormulirPage',
    components: {
        NavbarComponent,
        // FooterComponent
    },
    data(){
        return{
        initialSlide: 4,
        form: {
            namaInstansi: '',
            emailInstansi: '',
            phone: '',
            jumlahPeserta: '',
            tanggalKeberangkatan: '',
            tanggalKepulangan: '',
            kotaTujuan: '',
            akomodasi: '',
            temaWisata: '',
            budget: '',
            service: ''
            },
        today: '',
        isModalOpen: false,
        }
    },
    methods:{
        handleSubmit() {
        const formData = new FormData();
        Object.keys(this.form).forEach(key => {
            formData.append(key, this.form[key]);
        });

        fetch('http://travelwithamarta.com/sendmail.php', {
            method: 'POST',
            body: formData,
        })
        .then(response => response.text())
        .then(data => {
            console.log(data);
            alert('Email sent successfully!');
            location.reload();
        })
        .catch(error => {
            console.error('Error:', error);
            alert('Failed to send email.');
        });
        },
        setTodayDate() {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0');
            const day = String(today.getDate()).padStart(2, '0');
            this.today = `${year}-${month}-${day}`;
        },
    }
}
</script>

<style>
.bg-opacity-50 {
    background: rgba(0,0,0,0.8);

}
</style>