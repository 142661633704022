<template>
    <div>
        <NavbarComponent/>
        <section class="container-dqa mx-auto pt-16 pb-16 mb-16">
            <div class="mt-16 text-center">
                <h1 class="fs-30 ff-Custom-1">Eksplor Keindahan Jepang: Liburan Impian Menanti! 🌸✈️</h1>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-5 mt-10 px-4">
                <div v-for="(produk, index) in produkList" :key="index" class="border shadow rounded-lg">
                    <router-link :to="{ name: 'ProdukDetail', params: { id: index + 1 } }">
                        <img :src="produk.image" :alt="produk.name" class="img-fluid rounded-lg">
                    </router-link>
                    <div class="px-3 flex flex-col gap-3 py-3">
                        <h2 class="font-bold">{{ produk.name }}</h2>
                    </div>
                </div>
            </div>
        </section>
        <FooterComponent/>
    </div>
</template>

<script>
import NavbarComponent from '../components/navbar.vue'
import FooterComponent from '../components/footer.vue'

export default {
    name: 'ProdukComponent',
    components: {
        NavbarComponent,
        FooterComponent
    },
    data() {
        return {
            produkList: [
                { id: 1, name: "Japan Edutrip Custom Group", image: require('../assets/images/produk-3.png') },
                { id: 2, name: "PRIVATE GROUP EDUTRIP - KANSAI JAPAN", image: require('../assets/images/produk-2.png') },
                { id: 3, name: "PRIVATE GROUP EDUTRIP - KANTO", image: require('../assets/images/produk-1.png') }
            ]
        };
    }
}
</script>