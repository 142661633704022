<template>
    <div>
        <NavbarComponent/>
        <section class="py-16 min-h-screen px-3">
            <div class="container mx-auto">
                <div class="text-center py-10">
                    <h1 class="fs-48 ff-Custom-1">Galeri Perjalanan EduTrip Jepang</h1>
                    <p class="container-dqa">Selamat datang di galeri perjalanan EduTrip Jepang! ✨
                        Nikmati momen-momen berharga saat kami menjelajahi keindahan budaya, teknologi, dan sejarah Negeri Sakura. Dari megahnya Tokyo hingga ketenangan Kyoto, setiap pengalaman membawa wawasan baru dan kenangan tak terlupakan.</p>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 md:grid-cols-5 gap-5">
                    <div v-for="(item,index) in image" :key="index+1">
                        <img :src="item" alt="gallery image">
                    </div>
                </div>
            </div>
        </section>
        <FooterComponent/>
    </div>
</template>
<script>
import NavbarComponent from '../components/navbar.vue'
import FooterComponent from '../components/footer.vue'
export default{
    name:'GalleryComponent',
    components:{
        NavbarComponent,
        FooterComponent
    },
    data(){
        return{
            image: [
                require('@/assets/images/galeri/IMG_0019.jpg'),
                require('@/assets/images/galeri/IMG_0104.jpg'),
                require('@/assets/images/galeri/IMG_0311.jpg'),
                require('@/assets/images/galeri/IMG_0475.jpg'),
                require('@/assets/images/galeri/IMG_3671.jpg'),
                require('@/assets/images/galeri/IMG_0774.jpg'),
                require('@/assets/images/galeri/IMG_0934.jpg'),
                require('@/assets/images/galeri/IMG_0968.jpg'),
                require('@/assets/images/galeri/IMG_1246.jpg'),
                require('@/assets/images/galeri/IMG_1407.jpg'),
                require('@/assets/images/galeri/IMG_3560.jpg'),
                require('@/assets/images/galeri/IMG_3868.jpg'),
                require('@/assets/images/galeri/IMG_3880.jpg'),
                require('@/assets/images/galeri/IMG_4003.jpg'),
                require('@/assets/images/galeri/IMG_4013.jpg'),
                require('@/assets/images/galeri/IMG_4105.jpg'),
                require('@/assets/images/galeri/IMG_4204.jpg'),
                require('@/assets/images/galeri/IMG_9952.jpg'),
                require('@/assets/images/galeri/IMG_9988.jpg'),
                require('@/assets/images/galeri/IMG_5219.png'),
                require('@/assets/images/galeri/IMG_6785.png'),
                require('@/assets/images/galeri/IMG_7080.png'),
                require('@/assets/images/galeri/IMG_7260.png'),
                require('@/assets/images/galeri/IMG_7431.png'),
                require('@/assets/images/galeri/IMG_7792.png'),
                require('@/assets/images/galeri/IMG_20230514_205108.jpg'),
            ]
        }
    }
}
</script>