<template>
    <section ref="navbar" id="navbar" class="navbar-dqa pt-2 pb-2 pl-4 pr-4 shadow" style="z-index: 10000;">
        <div class="container mx-auto d-flex justify-space-between">
            <a href="/">
            <img src="../assets/images/logobrand.png" width="100" height="45">
            </a>
            <div class="hide-on-mobile align-self-center">
            <ul class="d-flex ga-5">
                <li><router-link to="/tentang-kami" class="fs-20 text--white cursor-pointer">Tentang Kami</router-link></li>
                <li><router-link to="/layanan" class="fs-20 text--white cursor-pointer">Layanan</router-link></li>
                <li><router-link to="/produk" class="fs-20 text--white cursor-pointer">Produk</router-link></li>
                <li><router-link to="/Galeri"  class="fs-20 text--white cursor-pointer" >Galeri</router-link></li>
                <li><router-link to="/formulir" class="fs-20 text--white cursor-pointer">Formulir</router-link></li>
                <!-- <li><a href="#contact" class="fs-20 text--white cursor-pointer">Kontak</a></li> -->
            </ul>
            </div>
            <div class="show-on-mobile align-self-center">
            <div class="border-2px-white br-5px pr-1 pl-1 cursor-pointer" id="toggleBar" ref="toggleBars">
                <i class="fa-solid fa-bars text--white"></i>
            </div>
            </div>
        </div>
        </section>
        <section ref="navbarMobile" id="navbar-mobile" class="show-on-mobile position-fixed w-100 bg-maincolor transition-06s border-t-md" style="z-index: 10000; top:64px; display:none;">
        <ul class="d-flex-col ga-5">
            <li class="ml-4 pt-2"><router-link to="/tentang-kami" class="fs-20 text--white cursor-pointer" >Tentang Kami</router-link></li>
            <li class="ml-4"><router-link to="/layanan" class="fs-20 text--white cursor-pointer">Layanan</router-link></li>
            <li class="ml-4"><router-link to="/produk" class="fs-20 text--white cursor-pointer">Produk</router-link></li>
            <li class="ml-4"><router-link to="/Galeri"  class="fs-20 text--white cursor-pointer">Galeri</router-link></li>
            <li class="ml-4"><router-link to="/formulir"  class="fs-20 text--white cursor-pointer">Formulir</router-link></li>
            <!-- <li class="ml-4 pb-2"><a href="#contact" class="fs-20 text--white cursor-pointer">Kontak</a></li> -->
            
        </ul>
    </section>
</template>
<script>
export default {
  name: 'NavbarComponent',
  mounted() {
    // Akses elemen langsung dari $refs
    var navbar = this.$refs.navbar;
    var navbarMobile = this.$refs.navbarMobile;
    var toggleBars = this.$refs.toggleBars;
    if (this.$route.path === '/') {
        window.addEventListener("scroll", function () {
        navbar.classList.toggle("sticky-navbar", window.scrollY > 0);
        navbarMobile.classList.toggle("sticky-navbar", window.scrollY > 0);
        });
    }

    if (this.$route.path !== '/') {
      navbar.classList.add("sticky-navbar");
      navbarMobile.classList.add("sticky-navbar");
    }

    toggleBars.addEventListener("click", function () {
      navbarMobile.style.display = (navbarMobile.style.display === 'none' || navbarMobile.style.display === '') ? 'block' : 'none';
    });


    var navbarMobileLinks = navbarMobile.querySelectorAll('li');
    navbarMobileLinks.forEach(function (link) {
      link.addEventListener('click', function () {
        navbarMobile.style.display = 'none';
      });
    });
  }
}
</script>
