<template>
    <div>
        <NavbarComponent/>
        <section class="container mx-auto py-16 mt-10 px-3">
            <div v-if="productId == 1">
                <div  class="flex flex-col md:flex-row gap-5">
                    <div class="w-full md:w-[50%]">
                        <img src="../assets/images/produk-3.png" alt="produk-1" class="rounded-lg max-h-[800px] w-full">
                    </div>
                    <div class="w-full md:w-[50%]">
                        <div class="border-b-2 w-fit mb-5 pb-2">
                            <h1 class="text-2xl md:text-4xl font-bold">Japan Edutrip Custom Group</h1>
                        </div>
                        <div class="mb-5">
                            <h3 class="text-1xl font-bold">PRIVATE GROUP EDUTRIP JAPAN</h3>
                            <h3 class="text-1xl font-bold">by Travel With Amarta</h3>
                        </div>
                        <div class="mb-5">
                            <h3 class="font-bold">[EXPLORE AND EXPERIENCE JAPAN’S BEST HIGHLIGHT ]</h3>
                            <p class="font-bold">Kalau selama ini ke Jepang itu jalan-jalan aja, TWA ingin ajak kamu untuk #ExploreExperience Jepang dari fitur terbaiknya 💯 Kita bisa merasakan lamgsung budaya tradisional dan pop culture Jepang, belajar langsung kenapa Jepang itu bersih, canggih, penanggulangan bencananya keren langsung dari ahlinya!</p>
                        </div>
                        <div class="mb-5">
                            <h3 class="font-bold">PACKAGE A & B</h3>
                            <h3 class="font-bold">OSAKA - KYOTO - NARA - KOBE</h3>
                        </div>
                        <div class="mb-5 flex flex-col  md:flex-row">
                            <div>
                                <h3 class="font-bold">Fasilitas lengkap! Meliputi:</h3>
                                <ul>
                                    <li style="display:block"> ✨Hotel</li>
                                    <li style="display:block"> ✨Transportasi private bus</li>
                                    <li style="display:block"> ✨Tiket masuk tempat wisata</li>
                                    <li style="display:block"> ✨Konsumsi 3x sehari (breakfast, lunch, dinner)</li>
                                    <li style="display:block"> ✨Spanduk dan travel kit</li>
                                    <li style="display:block"> ✨Tour leader dan interpreter</li>
                                    <li style="display:block"> ✨Tips</li>
                                </ul>
                            </div>
                            <div>
                                <h3 class="font-bold">Kalau ikut program ini, kamu bisa dapetin:</h3>
                                <ol class="pl-3">
                                    <li> Disaster prevention and resillience class</li>
                                    <li> Waste management class</li>
                                    <li> Learn Japan most innovative tech</li>
                                    <li> Experience the iconic Japan pop culture and heritage</li>
                                </ol>
                            </div>
                        </div>
                        <div class="mb-4">
                            <h3 class="font-bold">Benefit:</h3>
                            <ol class="pl-3">
                                <li> Sertifikat</li>
                                <li> FREE OF CHARGE PENDAMPING</li>
                                <li> Jadwal keberangkatan disesuaikan dengan kebutuhan</li>
                            </ol>
                        </div>
                        <p class="font-bold">Yuk langsung aja daftarkan grup kamu untuk ikut Edutrip bareng TWA! Untuk reservasi dan konsultasi dapat menghubungi via WhatsApp:</p>
                        <a  href="https://wa.link/645yp9" class="w-fit text-white">
                            <div class="bg-green-600 rounded-lg flex justify-center py-2 mb-2 px-10">
                                <i class="fa-brands fa-whatsapp pr-2 content-center"></i>Hubungi
                            </div>
                        </a>
                    </div>
    
                </div>
            </div>

            <div v-if="productId == 2">
                <div  class="flex flex-col md:flex-row gap-5">
                    <div class="w-full md:w-[50%]">
                        <img src="../assets/images/produk-2.png" alt="produk-2" class="rounded-lg max-h-[800px] w-full">
                    </div>
                    <div class="w-full md:w-[50%]">
                        <div class="border-b-2 w-fit mb-5 pb-2">
                            <h1 class="text-2xl md:text-4xl font-bold">PRIVATE GROUP EDUTRIP - KANSAI JAPAN</h1>
                        </div>
                        <div class="mb-5">
                            <h3 class="text-1xl font-bold">PRIVATE GROUP EDUTRIP JAPAN</h3>
                            <h3 class="text-1xl font-bold">by Travel With Amarta</h3>
                        </div>
                        <div class="mb-5">
                            <h3 class="font-bold">[EXPLORE AND EXPERIENCE JAPAN’S BEST HIGHLIGHT ]</h3>
                            <p class="font-bold">Kalau selama ini ke Jepang itu jalan-jalan aja, TWA ingin ajak kamu untuk #ExploreExperience Jepang dari fitur terbaiknya 💯 Kita bisa merasakan lamgsung budaya tradisional dan pop culture Jepang, belajar langsung kenapa Jepang itu bersih, canggih, penanggulangan bencananya keren langsung dari ahlinya!</p>
                        </div>
                        <div class="mb-5">
                            <h3 class="font-bold">PACKAGE A & B</h3>
                            <h3 class="font-bold">OSAKA - KYOTO - NARA - KOBE</h3>
                        </div>
                        <div class="mb-5 flex flex-col  md:flex-row">
                            <div>
                                <h3 class="font-bold">Fasilitas lengkap! Meliputi:</h3>
                                <ul>
                                    <li style="display:block"> ✨Hotel</li>
                                    <li style="display:block"> ✨Transportasi private bus</li>
                                    <li style="display:block"> ✨Tiket masuk tempat wisata</li>
                                    <li style="display:block"> ✨Konsumsi 3x sehari (breakfast, lunch, dinner)</li>
                                    <li style="display:block"> ✨Spanduk dan travel kit</li>
                                    <li style="display:block"> ✨Tour leader dan interpreter</li>
                                    <li style="display:block"> ✨Tips</li>
                                </ul>
                            </div>
                            <div>
                                <h3 class="font-bold">Kalau ikut program ini, kamu bisa dapetin:</h3>
                                <ol class="pl-3">
                                    <li> Disaster prevention and resillience class</li>
                                    <li> Waste management class</li>
                                    <li> Learn Japan most innovative tech</li>
                                    <li> Experience the iconic Japan pop culture and heritage</li>
                                </ol>
                            </div>
                        </div>
                        <div class="mb-4">
                            <h3 class="font-bold">Benefit:</h3>
                            <ol class="pl-3">
                                <li> Sertifikat</li>
                                <li> FREE OF CHARGE PENDAMPING</li>
                                <li> Jadwal keberangkatan disesuaikan dengan kebutuhan</li>
                            </ol>
                        </div>
                        <p class="font-bold">Yuk langsung aja daftarkan grup kamu untuk ikut Edutrip bareng TWA! Untuk reservasi dan konsultasi dapat menghubungi via WhatsApp:</p>
                        <a  href="https://wa.link/hnrkrf" class="w-fit text-white">
                            <div class="bg-green-600 rounded-lg flex justify-center py-2 mb-2 px-10">
                                <i class="fa-brands fa-whatsapp pr-2 content-center"></i>Hubungi
                            </div>
                        </a>
                    </div>
    
                </div>
            </div>

            <div v-if="productId == 3">
                <div  class="flex flex-col md:flex-row gap-5">
                    <div class="w-full md:w-[50%]">
                        <img src="../assets/images/produk-1.png" alt="produk-2" class="rounded-lg max-h-[800px] w-full">
                    </div>
                    <div class="w-full md:w-[50%]">
                        <div class="border-b-2 w-fit mb-5 pb-2">
                            <h1 class="text-2xl md:text-4xl font-bold">PRIVATE GROUP EDUTRIP - KANTO</h1>
                        </div>
                        <div class="mb-5">
                            <h3 class="text-1xl font-bold">PRIVATE GROUP EDUTRIP JAPAN</h3>
                            <h3 class="text-1xl font-bold">by Travel With Amarta</h3>
                        </div>
                        <div class="mb-5">
                            <h3 class="font-bold">[EXPLORE AND EXPERIENCE JAPAN’S BEST HIGHLIGHT ]</h3>
                            <p class="font-bold">Kalau selama ini ke Jepang itu jalan-jalan aja, TWA ingin ajak kamu untuk #ExploreExperience Jepang dari fitur terbaiknya 💯 Kita bisa merasakan lamgsung budaya tradisional dan pop culture Jepang, belajar langsung kenapa Jepang itu bersih, canggih, penanggulangan bencananya keren langsung dari ahlinya!</p>
                        </div>
                        <div class="mb-5">
                            <h3 class="font-bold">PACKAGE C & D</h3>
                            <h3 class="font-bold">TOKYO - KAWAGOE - FUJI / KAMAKURA</h3>
                        </div>
                        <div class="mb-5 flex flex-col gap-4 lg:flex-row">
                            <div>
                                <h3 class="font-bold">Fasilitas lengkap! Meliputi:</h3>
                                <ul>
                                    <li style="display:block"> ✨Hotel</li>
                                    <li style="display:block"> ✨Transportasi private bus</li>
                                    <li style="display:block"> ✨Tiket masuk tempat wisata</li>
                                    <li style="display:block"> ✨Konsumsi 3x sehari (breakfast, lunch, dinner)</li>
                                    <li style="display:block"> ✨Spanduk dan travel kit</li>
                                    <li style="display:block"> ✨Tour leader dan interpreter</li>
                                    <li style="display:block"> ✨Tips</li>
                                </ul>
                            </div>
                            <div>
                                <h3 class="font-bold">Kalau ikut program ini, kamu bisa dapetin:</h3>
                                <ol class="pl-3">
                                    <li> Disaster prevention and resillience class</li>
                                    <li> Waste management class</li>
                                    <li> Learn Japan most innovative tech</li>
                                    <li> Experience the iconic Japan pop culture and heritage</li>
                                </ol>
                            </div>
                        </div>
                        <div class="mb-4">
                            <h3 class="font-bold">Benefit:</h3>
                            <ol class="pl-3">
                                <li> Sertifikat</li>
                                <li> FREE OF CHARGE PENDAMPING</li>
                                <li> Jadwal keberangkatan disesuaikan dengan kebutuhan</li>
                            </ol>
                        </div>
                        <p class="font-bold">Yuk langsung aja daftarkan grup kamu untuk ikut Edutrip bareng TWA! Untuk reservasi dan konsultasi dapat menghubungi via WhatsApp:</p>
                        <a  href="https://wa.link/hnrkrf" class="w-fit text-white">
                            <div class="bg-green-600 rounded-lg flex justify-center py-2 mb-2 px-10">
                                <i class="fa-brands fa-whatsapp pr-2 content-center"></i>Hubungi
                            </div>
                        </a>
                    </div>
    
                </div>
            </div>

            <div class="border-b-2 w-fit mb-5 pb-2">
                <h2 class="text-2xl md:text-4xl font-bold">Jelajahi Paket Kami Lainnya </h2>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-5 mt-10 px-4">
                <div v-for="(produk, index) in filteredProdukList" :key="index" class="border shadow rounded-lg">
                    <router-link :to="{ name: 'ProdukDetail', params: { id: produk.id } }" @click="handleClick">
                        <img :src="produk.image" :alt="produk.name" class="img-fluid rounded-lg">
                    </router-link>
                    <div class="px-3 flex flex-col gap-3 py-3">
                        <h2 class="font-bold">{{ produk.name }}</h2>
                    </div>
                </div>
            </div>
        </section>
        <FooterComponent/>
    </div>
</template>
<script>
import { useRoute } from 'vue-router';
import NavbarComponent from '../components/navbar.vue'
import FooterComponent from '../components/footer.vue'
export default{
    name:'ProdukDetail',
    components:{
        NavbarComponent,
        FooterComponent
    },
    setup() {
        const route = useRoute();
        return {
            productId: route.params.id,
            produkList: [
                { id: 1, name: "Japan Edutrip Custom Group", image: require('../assets/images/produk-3.png') },
                { id: 2, name: "PRIVATE GROUP EDUTRIP - KANSAI JAPAN", image: require('../assets/images/produk-2.png') },
                { id: 3, name: "PRIVATE GROUP EDUTRIP - KANTO", image: require('../assets/images/produk-1.png') }
            ]
        };
    },
    methods:{
        handleClick() {
            window.scrollTo({ top: 0, behavior: 'smooth' }); 
            setTimeout(() => {
                window.location.reload();
            }); 
        }
    },
    computed: {
        filteredProdukList() {
            const excludedIds = this.productId;
            return this.produkList.filter(produk => !excludedIds.includes(produk.id));
        }
    }
}
</script>