<template>
    <div>
        <NavbarComponent/>

        <section id="about" class="pt-16 pl-2 pr-2">
            <div class="container mx-auto d-flex mt-16 pb-16">
                <div class="grid-box-2 ">
                <v-col class="d-flex-col justify-center">
                    <div class="pb-2">
                    <span class="fs-48 ff-Custom-1">Tentang Kami</span>
                    </div>
                    <div class="text-align-justify">
                    <span class="fs-16">CV Travel With Amarta (TWA) merupakan perusahaan konsultan yang menyediakan jasa perjalanan ke Jepang. Kami telah beroperasi sejak 2016 (melalui CV Prima Interindo). Dengan pembaruan, kami hadir untuk memberikan pengalaman dan pelayanan terbaik melalui berbagai pilihan program yang dapat disesuaikan dengan kebutuhan klien. Kami juga dapat menyediakan halal edutravel bagi instansi yang membutuhkan. </span><a href="https://drive.google.com/drive/folders/1jyQ9uqq6zfB5Xb4ZTqHEqQisVM3eXIHs" class="text-red-darken-1 fw-600" target="_blank">Cek legalitas kami disini.</a>
                    </div>
                </v-col>
                <v-col class="text-align-center">
                <div class="grid-box-2 about-img">
                    <div class="image-container">
                    <img class="img-fluid" src="../assets/images/performancekebudayaan.png" alt="Performance Kebudayaan">
                    <span class="bg-red-darken-4 br-20px pa-1">Performance Kebudayaan</span>
                    </div>
                    <div class="image-container">
                    <img class="img-fluid" src="../assets/images/museumvisit.png" alt="Museum Visit">
                    <span class="bg-red-darken-4 br-20px pa-1">Museum Visit</span>
                    </div>
                    <div class="image-container">
                    <img class="img-fluid" src="../assets/images/companyvisit.png" alt="Company Visit">
                    <span class="bg-red-darken-4 br-20px pa-1">Company Visit</span>
                    </div>
                    <div class="image-container">
                    <img class="img-fluid" src="../assets/images/kunjunganpersahabatan.png" alt="Kunjungan Persahabatan">
                    <span class="bg-red-darken-4 br-20px pa-1">Kunjungan Persahabatan</span>
                    </div>
                </div>
                </v-col>
                </div>
            </div>
            
            <div class="container mx-auto d-flex mt-16 pb-16">
                <div class="grid-box-2 ">
                <v-col class="text-align-center">
                    <img class="img-fluid image-shadow" src="../assets/images/edutravel.png" height="800" width="500">
                </v-col>
                <v-col class="d-flex-col justify-center">
                    <div class="pb-2">
                    <span class="fs-48 ff-Custom-1">Tentang Edutravel</span>
                    </div>
                    <div class="text-align-justify">
                    <span class="fs-16">Edutravel adalah metode pembelajaran yang menggabungkan konsep belajar formal dan non formal. Program ini menyediakan sarana eksplorasi aktif bagi peserta dengan tujuan agar mereka dapat mengaplikasikan ilmu secara langsung di dunia sekitar. Jepang menjadi fokus utama destinasi kami karena menawarkan banyak situs wisata edukasi menarik untuk dikunjungi. Selain itu Jepang diakui secara internasional sebagai negara yang memiliki sistem pendidikan percontohan di Asia.</span>
                    </div>
                </v-col>
                </div>
            </div>

            <div class="py-1">
                <div class="container-dqa mt-16 pb-16">
                    <div class="flex justify-center">
                        <h2 class="ff-Custom-1 text-[40px]">Mengapa Memilih Kami</h2>
                    </div>
                    <div class="grid grid-cols-4 md:grid-cols-12 gap-5 py-5">
                        <div class="col-span-4 border shadow rounded-lg pa-5 bg-white">
                            <div class="flex justify-center pb-2 ">
                                <i class="fa-solid fa-route text-[50px] border rounded-full w-[100px] h-[100px] content-center text-center bg-white"></i>
                            </div>
                            <p class="text-[#2c3e50]">Membuat dan mengakomodasi program unik yang disesuaikan dengan kebutuhan grup</p>
                        </div>
                        <div class="col-span-4 border shadow rounded-lg pa-5 bg-white">
                            <div class="flex justify-center pb-2">
                                <i class="fa-solid fa-chalkboard text-[50px] border rounded-full w-[100px] h-[100px] content-center text-center bg-white"></i>
                            </div>
                            <p class="text-[#2c3e50]">Menghubungkan kemitraan Indonesia-Jepang melalui program kurikulum seperti Sister School, Homestay, dan Student Exchange.</p>
                        </div>
                        <div class="col-span-4 border shadow rounded-lg pa-5 bg-white">
                            <div class="flex justify-center pb-2">
                                <i class="fa-solid fa-book text-[50px] border rounded-full w-[100px] h-[100px] content-center text-center bg-white"></i>
                            </div>
                            <p class="text-[#2c3e50]">Mengakomodasi untuk belajar dari tenaga ahli dari setiap bidangnya</p>
                        </div>
                        <div class="col-span-4 col-start-1 md:col-start-3 shadow border rounded-lg pa-5 bg-white">
                            <div class="flex justify-center pb-2">
                                <i class="fa-solid fa-money-bill-wave text-[50px] border rounded-full w-[100px] h-[100px] content-center text-center bg-white"></i>
                            </div>
                            <p class="text-[#2c3e50]">Fleksibilatas harga mengikuti permintaan disesuaikan dengan fasilitas akomodasi yang beragam</p>
                        </div>
                        <div class="col-span-4  border rounded-lg pa-5 bg-white">
                            <div class="flex justify-center pb-2">
                                <i class="fa-solid fa-bowl-food text-[50px] border rounded-full w-[100px] h-[100px] content-center text-center bg-white"></i>
                            </div>
                            <p class="text-[#2c3e50]">Menyediakan halal tur dengan mengkurasi pilihan bento, snack, serta mengingatkan jam dan tempat sholat (jika tersedia)</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-dqa  mt-16 pb-16">
                <div class="flex justify-center">
                    <h2 class="ff-Custom-1 text-[40px]">Kenapa Pilih Jepang</h2>
                </div>
                <div class="grid grid-cols-1 gap-5 py-5">
                    <div class="flex gap-3">
                        <div>
                            <p class="rounded-full w-[50px] h-[50px] bg-white border text-[23px] content-center text-center">1</p>
                        </div>
                        <div class="content-center">
                            <p class="text-[25px]">Termasuk negara maju yang Membawa kontribusi besar terhadap perkembangan ilmu pengetahuan dan teknologi yang dipadukan dengan berbagai inovasi</p>
                        </div>
                    </div>
                    <div class="flex gap-3">
                        <div>
                            <p class="rounded-full w-[50px] h-[50px] bg-white border text-[23px] content-center text-center">2</p>
                        </div>
                        <div class="content-center">
                            <p class="text-[25px]">Merupakan negara dengan perkembangan teknologi tertinggi ke-2 di dunia</p>
                        </div>
                    </div>
                    <div class="flex gap-3">
                        <div>
                            <p class="rounded-full w-[50px] h-[50px] bg-white border text-[23px] content-center text-center">3</p>
                        </div>
                        <div class="content-center">
                            <p class="text-[25px]">Termasuk negara dengan pendidikan terbaik ke-4 di dunia dan menyediakan banyak situs wisata edukasi secara interaktif</p>
                        </div>
                    </div>
                    <div class="flex gap-3">
                        <div>
                            <p class="rounded-full w-[50px] h-[50px] bg-white border text-[23px] content-center text-center">4</p>
                        </div>
                        <div class="content-center">
                            <p class="text-[25px]">Memiliki adat budaya yang kuat. Penduduknya memegang teguh warisan leluhur sehingga tetap menjadikannya pionir untuk perkembangan teknologi dan pariwisata</p>
                        </div>
                    </div>
                    <div class="flex gap-3">
                        <div>
                            <p class="rounded-full w-[50px] h-[50px] bg-white border text-[23px] content-center text-center">5</p>
                        </div>
                        <div class="content-center">
                            <p class="text-[25px]">Dikenal sebagai negara yang menjunjung tinggi adab seperti kejujuran, kedispilinan, dan kerja keras</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <FooterComponent/>
    </div>
</template>
<script>
import NavbarComponent from '../components/navbar.vue'
import FooterComponent from '../components/footer.vue'
export default {
  name: 'AboutPage',
  components:{
    NavbarComponent,
    FooterComponent
  }
}
</script>