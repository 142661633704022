import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../src/views/HomePage.vue'
import About from '../src/views/About.vue'
import Layanan from '../src/views/Layanan.vue'
import Formulir from '../src/views/Formulir.vue'
import Produk from '../src/views/Produk.vue'
import Gallery from '../src/views/Galeri.vue'
import ProdukDetail from '../src/views/ProdukDetail.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/tentang-kami',
    name: 'About',
    component: About
  },
  {
    path: '/layanan',
    name: 'Layanan',
    component: Layanan
  },
  {
    path: '/produk',
    name: 'Produk',
    component: Produk
  },
  {
    path: '/produk/:id',
    name: 'ProdukDetail',
    component: ProdukDetail
  },
  {
    path: '/formulir',
    name: 'Formulir',
    component: Formulir
  },
  {
    path: '/galeri',
    name: 'Galeri',
    component: Gallery
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
          return savedPosition;
      } else {
          return { top: 0 }; 
      }
  },
})

export default router