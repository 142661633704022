<template>
    <div>
        <NavbarComponent/>
        <section id="service" class="pt-16 " >
            <div class="container mx-auto d-flex-col">
                <div class="align-self-center">
                <span class="fs-48 ff-Custom-1">Layanan</span>
                </div>
    
                <div class="grid-box-service ga-16 pt-5 pl-3 pr-3" >
                <div class="d-flex-col">
                    <div class="d-flex justify-center mb-2">
                        <div class="border-point-red"></div>
                        <h2 class="fs-30 ff-Custom-1">Perencanaan Program</h2>
                    </div>
                    <span class="fs-16 text-align-justify">Kami membantu klien untuk menentukan tujuan serta fokus pembelajaran sesuai kurikulum dan program sekolah. Dilanjutkan dengan merancang program wisata edukasi yang bermanfaat untuk peserta didik, yang disesuaikan dengan kebutuhan program pembelajaran serta anggaran sekolah.</span>
                </div>
                <div class="d-flex-col">
                    <div class="d-flex justify-center mb-2">
                    <div class="border-point-red"></div>
                    <h2 class="fs-30 ff-Custom-1">Pemandu Perjalanan</h2>
                    </div>
                    <div class="d-flex">
                    <span class="text-align-justify fs-16">Kami membantu klien selama perjalanan dengan memberikan panduan dan pendampingan perjalanan pada aspek transportasi, akomodasi serta konsumsi, berikut dengan fasilitas penerjemah selama proses pembelajaran.</span>
                    </div>
                </div>
                <div>
                <div class="d-flex justify-center">
                    <div class="border-point-red"></div>
                    <h2 class="fs-30 ff-Custom-1">Tujuan Perjalanan :</h2>
                </div>
                <div class="d-flex">
                    <div class="grid-box-2 mt-1">
                    <div class="d-flex-col">
                        <span class="fs-16">•	Perusahaan & industri</span>
                        <span class="fs-16">•	Sekolah & universitas</span>
                        <span class="fs-16">•	Instansi pemerintah</span>
                        <span class="fs-16">•	Yayasan sosial</span>
                        <span class="fs-16">•	Museum</span>
                    </div>
                    <div class="d-flex-col">
                        <span class="fs-16">•	Cagar alam & hutan kota</span>
                        <span class="fs-16">•	Desa lokal</span>
                        <span class="fs-16">•	Objek wisata</span>
                        <span class="fs-16">•	Pusat perbelanjaan</span>
                        <span class="fs-16">•	Tempat ibadah</span>
                    </div>
                    </div>
                </div>
                </div>
                </div>

                <div class="my-6">
                    <div class="d-flex justify-center mb-2">
                        <div class="border-point-red"></div>
                        <h2 class="fs-30 ff-Custom-1">Contoh Aktivitas Kunjungan</h2>
                    </div>
                    <div class="border br-5px pa-5 grid-box-2 ga-5" style="background-color: #F3F2EF;">
                        <div class="d-flex">
                        <div class="image-container">
                            <img src="../assets/images/kansaiairport.png" alt="Kansai Airport" class="img-fluid small-img image-shadow">
                        </div>
                        <div class="pl-2 d-flex-col justify-center">
                            <h2 class="fs-20 bg-yellowqe pl-2 pr-2 w-fit">Kansai Airport</h2>
                            <span>Pembelajaran  sistem sanitasi air dan pembuatan lahan reklamasi</span>
                        </div>
                        </div>
        
                        <div class="d-flex">
                        <div class="image-container">
                            <img src="../assets/images/maishima.png" alt="Maishima Inceneration Plant" class="img-fluid small-img image-shadow">
                        </div>
                        <div class="pl-2 d-flex-col justify-center">
                            <h2 class="fs-20 bg-yellowqe pl-2 pr-2 w-fit">Maishima Inceneration Plant</h2>
                            <span>Pembelajaran teknologi pengelolaan dan pemanfaatan energi  dari sampah</span>
                        </div>
                        </div>
        
                        <div class="d-flex">
                        <div class="image-container">
                            <img src="../assets/images/LifeSafety.png" alt="Life Safety Center" class="img-fluid small-img image-shadow">
                        </div>
                        <div class="pl-2 d-flex-col justify-center">
                            <h2 class="fs-20 bg-yellowqe pl-2 pr-2 w-fit">Life Safety Center</h2>
                            <span>Kunjungan pembelajaran teknologi perakitan mobil </span>
                        </div>
                        </div>
                        
                        <div class="d-flex">
                        <div class="image-container">
                            <img src="../assets/images/shirakawa.png" alt="Shirakawa-go" class="img-fluid small-img image-shadow">
                        </div>
                        <div class="pl-2 d-flex-col justify-center">
                            <h2 class="fs-20 bg-yellowqe pl-2 pr-2 w-fit">Shirakawa-go</h2>
                            <span>Pembelajaran perkembangan ilmu pengetahuan sains dan alam semesta</span>
                        </div>
                        </div>
        
                        <div class="d-flex">
                        <div class="image-container">
                            <img src="../assets/images/edowonderlant.png" alt="Edo Wonderland" class="img-fluid small-img image-shadow">
                        </div>
                        <div class="pl-2 d-flex-col justify-center">
                            <h2 class="fs-20 bg-yellowqe pl-2 pr-2 w-fit">Edo Wonderland</h2>
                            <span>Pembelajaran teknologi pembuatan jembatan bawah air dan pemanfaatan lahan reklamasi</span>
                        </div>
                        </div>
        
                        <div class="d-flex">
                        <div class="image-container">
                            <img src="../assets/images/Amanohasidate.png" alt="Amanohashidate" class="img-fluid small-img image-shadow">
                        </div>
                        <div class="pl-2 d-flex-col justify-center">
                            <h2 class="fs-20 bg-yellowqe pl-2 pr-2 w-fit">Amanohashidate</h2>
                            <span>Pembelajaran perkembangan teknologi media massa di Jepang</span>
                        </div>
                        </div>
                    
                    </div>
                </div>
            </div>
        </section>
        <FooterComponent/>
    </div>
</template>
<script>
import NavbarComponent from '../components/navbar.vue'
import FooterComponent from '../components/footer.vue'
export default {
    name: 'LayananPage',
    components: {
        NavbarComponent,
        FooterComponent
    }
}
</script>